import { useForm } from "react-hook-form";
import { formSchema } from "./form.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../shared/modules/login";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../utils/user";
import { notifications } from "@mantine/notifications";

export const useLogin = () => {
  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(formSchema),
  });

  const { mutate, isLoading } = useMutation((data) => login(data), {
    onSuccess: async (res) => {
      await setUser(res?.data);
      notifications.show({
        title: "Tizimga kirish",
        message: "Tizimga muvaffaqiyatli kirdingiz",
        color: "teal",
      });
      navigate("/main");
    },
    onError: (err) => {
      notifications.show({
        title: "Error",
        message: err?.response?.data?.detail,
        color: "red",
      });
    },
  });
  const onSubmit = (data) => {
    mutate(data);
  };

  return {
    form,
    onSubmit,
    isLoading,
  };
};
