// /* eslint-disable @typescript-eslint/no-explicit-any */
import { useDisclosure } from "@mantine/hooks";
import { FormGroupProvider, MantinePasswordInput } from "./style";
import { Controller } from "react-hook-form";

export const InputPassword = ({ control, ...props }) => {
  const [visible, { toggle }] = useDisclosure(false);

  return (
    <>
      <Controller
        control={control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          formState: { errors },
        }) => (
          <FormGroupProvider>
            <MantinePasswordInput
              {...props}
              id={name}
              value={value || ""}
              onBlur={onBlur}
              onChange={onChange}
              onWheel={(e) => e.target.blur()}
              ref={ref}
              visible={visible}
              onVisibilityChange={toggle}
              error={errors[props.name] ? errors[props.name]?.message : ""}
              styles={{
                rightSection: errors[props.name] && {
                  border: "0.7l95144px solid #fa5252 !important",
                  borderLeftCoor: "transparent !important",
                },
              }}
            />
          </FormGroupProvider>
        )}
      />
    </>
  );
};
