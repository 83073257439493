import { useQuery } from "@tanstack/react-query";
import { getAllRequest } from "../../../shared/modules/getAllRequest";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function UseHooks() {
  const location = useLocation();
  let object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      status: params?.status ?? "all",
      from_date: params?.from_date ? new Date(params?.from_date) : null,
      search: params?.search ?? "",
    },
  });

  const { status, from_date, search } = form.watch();

  const { data: leftOptions = [], refetch } = useQuery(
    ["all_users_list", status, from_date, search],
    () =>
      getAllRequest(`/api/feedbacks`, {
        status: status === "undefined" || status === "all" ? undefined : status,
        from_date:
          (from_date === "undefined") |
          (from_date === undefined) |
          (from_date === null)
            ? undefined
            : dayjs(from_date).format("YYYY-MM-DD"),
        search: search === "undefined" || search === "" ? undefined : search,
      }),
    {
      select: (res) => {
        return res?.data;
      },
    }
  );
  const { data: userInfo } = useQuery(
    ["get_users_info"],
    () => getAllRequest(`/api/staff/`),
    {
      select: (res) => {
        return res?.data;
      },
    }
  );

  useEffect(() => {
    let newSearch = "";

    if (status !== null) {
      if (status >= 0 && status <= 4) {
        newSearch += `status=${status}`;
      }
    }

    if (from_date !== null) {
      const formattedDate = dayjs(from_date).format("YYYY-MM-DD");
      if (newSearch.length > 0) {
        newSearch += "&";
      }
      newSearch += `from_date=${formattedDate}`;
    }

    if (search !== null) {
      if (newSearch.length > 0) {
        newSearch += "&";
      }
      newSearch += `search=${search}`;
    }

    const path = new URLSearchParams(newSearch);
    const param = Object.fromEntries(path.entries());
    navigate({
      search: new URLSearchParams({
        ...params,
        ...param,
      }).toString(),
    });
    // eslint-disable-next-line
  }, [status, from_date, search]);

  return { form, leftOptions, userInfo, refetch };
}
