import styled from "@emotion/styled";

export const Container = styled.div`
  min-width: 130px;
  width: 100%;

  @media (max-width: 375px) {
    min-width: 0;
  }

  .mantine-DatePickerInput-input {
    background-color: #f3f3f4;
  }

  .mantine-InputPlaceholder-placeholder {
    font-size: 12px;
    color: #000;
  }
`;
