import styled from "@emotion/styled";

export const Wrapper = styled.div`
  height: 100vh;
`;

export const LoginLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .wrap-card {
    display: flex;
    width: 500px;
    height: 400px;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 12px;
  }
  
  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;
export const AuthLogo = styled.div`
  position: absolute;
`;
export const Title = styled.h3`
  font-size: 24px;
  font-family: "Inter", sans-serif;
  margin: 0;
`;

export const Form = styled("form")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-block: auto;

  .submit-btn {
    align-self: center;
    width: max-content;
    padding: 12px 24px;
    border-radius: 12px;
    font-size: 14px;

    &:active {
      transform: translateY(1.2px);
    }
  }
  .login-btn {
    border-radius: 12px;
    min-width: 120px;
    background: var(--background-surface-success-accent);
    box-shadow: 0 1px 2px 0 rgba(20, 21, 26, 0.06),
      0 1px 3px 0 rgba(20, 21, 26, 0.1);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.07px;
  }
`;
