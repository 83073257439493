import styled from "@emotion/styled";

export const Wrapper = styled.div`
  flex: 1;
  height: 100vh;
`;

export const Menu = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f3f3f4;
`;
