import LogoContainer from "../../Components/LogoContainer";
import ProfileContainer from "../../Components/ProfileContainer";
import LeftSide from "./components/LeftSide";
import RightSide from "./components/RightSide";
import { Menu, Wrapper } from "./style";
import { Grid } from "@mantine/core";
import { UseHooks } from "./hooks/useHooks";
import { FeedbackAnswerHook } from "./hooks/useFeedbackAnswer";

export default function Main() {
  const { form, leftOptions, userInfo, refetch } = UseHooks();
  const {
    rightForm,
    mutate,
    onSubmit,
    rightOptions,
    handleRejection,
    isLoading,
  } = FeedbackAnswerHook({ refetch });
  return (
    <Wrapper>
      <Menu>
        <LogoContainer />
        <ProfileContainer userInfo={userInfo} />
      </Menu>
      <Grid gutter={0}>
        <Grid.Col span={{ base: 6, md: 4, lg: 3 }}>
          <LeftSide form={form} leftOptions={leftOptions} mutate={mutate} />
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 8, lg: 9 }}>
          {rightOptions?.data && (
            <RightSide
              rightOptions={rightOptions?.data}
              form={rightForm}
              onSubmit={onSubmit}
              handleRejection={handleRejection}
              isLoading={isLoading}
            />
          )}
        </Grid.Col>
      </Grid>
    </Wrapper>
  );
}
