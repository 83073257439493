import { Container } from "./style";
import QXV from "../../assets/icons/Frame 39479 2.svg";

export default function LogoContainer() {
  return (
    <Container>
      <img src={QXV} alt="QXV" />
      <h1>Qishloq xo‘jaligi vazirligi</h1>
    </Container>
  );
}
