import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 91.96vh;
  overflow-y: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 2px solid #f3f3f4;
`;

export const ContainerFilter = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 24px;
  align-items: center;
  gap: 14px;
  background-color: #fff;
  border-bottom: 2px solid #f3f3f4;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;
