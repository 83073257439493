import dayjs from "dayjs";

export const clearUser = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const setUser = async (data) => {
  const user = JSON.stringify(data);
  localStorage.setItem("user", user);
  localStorage.setItem("date", dayjs());
};

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const date = localStorage.getItem("date");
  if (user) {
    const isExpiredAccess = dayjs(dayjs()).diff(date, "hour") < 23;
    return { ...user, isExpiredAccess };
  }
};
