import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  h1 {
    color: #292929;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }
`;
