export default function Search() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="MagnifyingGlass">
        <path
          id="Vector"
          d="M14.3538 13.6462L11.2244 10.5175C12.1314 9.42856 12.5837 8.03186 12.4872 6.61795C12.3906 5.20404 11.7527 3.88178 10.7061 2.92622C9.65949 1.97067 8.28478 1.4554 6.86795 1.4876C5.45111 1.5198 4.10123 2.09699 3.09912 3.0991C2.09701 4.10122 1.51981 5.4511 1.48761 6.86793C1.45541 8.28477 1.97069 9.65948 2.92624 10.7061C3.88179 11.7527 5.20405 12.3906 6.61796 12.4871C8.03188 12.5837 9.42857 12.1314 10.5175 11.2244L13.6463 14.3537C13.6927 14.4002 13.7479 14.437 13.8086 14.4622C13.8693 14.4873 13.9343 14.5003 14 14.5003C14.0657 14.5003 14.1308 14.4873 14.1915 14.4622C14.2522 14.437 14.3073 14.4002 14.3538 14.3537C14.4002 14.3073 14.4371 14.2521 14.4622 14.1914C14.4873 14.1307 14.5003 14.0657 14.5003 14C14.5003 13.9343 14.4873 13.8692 14.4622 13.8085C14.4371 13.7478 14.4002 13.6927 14.3538 13.6462ZM2.50001 6.99999C2.50001 6.10997 2.76393 5.23995 3.25839 4.49992C3.75286 3.7599 4.45566 3.18313 5.27793 2.84253C6.1002 2.50194 7.005 2.41282 7.87791 2.58646C8.75083 2.76009 9.55265 3.18867 10.182 3.81801C10.8113 4.44735 11.2399 5.24917 11.4135 6.12208C11.5872 6.995 11.4981 7.8998 11.1575 8.72207C10.8169 9.54433 10.2401 10.2471 9.50007 10.7416C8.76005 11.2361 7.89002 11.5 7.00001 11.5C5.80694 11.4987 4.66311 11.0241 3.81949 10.1805C2.97586 9.33688 2.50133 8.19306 2.50001 6.99999Z"
          fill="#6F6F6F"
        />
      </g>
    </svg>
  );
}
