import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TextInput } from "@mantine/core";

export const FormGroupProvider = styled("div")`
  & .mantine-PasswordInput-innerInput {
    height: auto;
  }
`;

export const MantineTextInput = styled(TextInput)`
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  width: 100%;
  input,
  input::placeholder,
  label {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
  }
  input {
    border-radius: 12px;
    color: #14151a;
    font-feature-settings: "tnum" on, "lnum" on !important;
    &::placeholder {
      color: var(--grey, #8e8e8e);
    }
    //&:focus{
    //  border-color: #26bd6c;
    //}
  }
  input:disabled {
    background: #f5f5f5 !important;
    border-color: #dbdbdb;
    color: var(--grey, #1a1a1a);
  }
  label {
    margin-bottom: 0.25rem;
    color: inherit;
    font-weight: 500;
    line-height: 20px;
  }
  ${(props) =>
    props.size === "xs" &&
    css`
      input {
        height: 32px;
        min-height: 30px;
        line-height: 30px;
      }
      label {
        font-size: 0.75rem;
      }
    `};
`;
