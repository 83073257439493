import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { FormGroupProvider, MantineTextInput } from "./style";

export const Input = ({
  control,
  defaultValue,
  onClick,
  handleChange,
  ...props
}) => {
  return (
    <>
      <Controller
        name={props.name}
        control={control}
        defaultValue={defaultValue ?? null}
        {...props}
        render={({
          field: { value, name, type, ref, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <FormGroupProvider>
            <MantineTextInput
              {...props}
              id={name}
              value={
                type !== "number" && value ? value.toString() : value || ""
              }
              onBlur={onBlur}
              onChange={(e) => {
                if (handleChange) {
                  handleChange(e);
                }
                onChange(e);
              }}
              onWheel={(e) => e.target.blur()}
              onClick={onClick}
              ref={ref}
              error={invalid ? error.message : ""}
              styles={{
                rightSection: invalid
                  ? {
                      border: "0.795144px solid #fa5252 !important",
                      borderLeftColor: "transparent !important",
                    }
                  : undefined,
              }}
            />
          </FormGroupProvider>
        )}
      />
    </>
  );
};

Input.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
};
