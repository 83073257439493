// /* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { PasswordInput } from "@mantine/core";
import { css } from "@emotion/react";

export const MantinePasswordInput = styled(PasswordInput)`
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  width: 100%;
  input,
  input::placeholder,
  label {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
  }
  & label {
    margin-bottom: 0.25rem;
    color: inherit;
    font-weight: 500;
    line-height: 20px;
  }
  & div {
    & div {
      border-radius: 12px;
      //&:focus-within{
      //  border-color: #26bd6c!important;
      //}
      min-height: 32px;
      input {
        &::placeholder {
          color: var(--grey, #8e8e8e);
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
  & .mantine-Input-rightSection {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
  }
  ${(props) =>
    props.size === "xs" &&
    css`
      input {
        height: 32px;
        min-height: 30px;
        line-height: 30px;
      }
      label {
        font-size: 0.75rem;
      }
    `};
`;

export const FormGroupProvider = styled("div")`
  & .mantine-PasswordInput-innerInput {
    height: auto;
  }
`;
