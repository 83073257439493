import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  .mantine-Input-input {
    background-color: #f3f3f4;
  }
  input::placeholder {
    color: #000;
  }
`;
