import styled from "@emotion/styled";
import { Grid } from "@mantine/core";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  border-bottom: 2px solid #f3f3f4;

  p {
    color: #303030;
    font-family: "Inter";
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }

  h1 {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    line-height: normal;
  }

  .id {
    color: #26bd6c;
  }

  .status {
    display: flex;
    text-align: center;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #fff;
    font-size: 12px;

    @media (max-width: 1500px) {
      font-size: 9px;
    }
    @media (max-width: 1200px) {
      font-size: 12px;
    }

    @media (max-width: 425px) {
      font-size: 10px;
    }

    @media (max-width: 375px) {
      font-size: 8px;
    }
  }
`;

export const GridContainer = styled(Grid)`
  width: 100%;
  border-bottom: 2px solid #f3f3f4;
  padding: 16px 24px;
  cursor: pointer;
  transition: background-color 0.2s ease;
`;

export const NavLinkStyle = styled(NavLink)`
  text-decoration: none;
  background-color: transparent;
  &.active-link {
    background-color: #ecf3f1;
    border-left: 5px solid #26bd6c;
  }
`;
