import { UploadButton } from "./style";
import { FileButton, Tooltip } from "@mantine/core";
import { Controller } from "react-hook-form";
import FileUploadIcon from "../../assets/icons/FileUpload";

export default function FileUpload(props) {
  return (
    <Controller
      {...props}
      render={({ field, formState: { errors } }) => (
        <FileButton
          {...field}
          accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,"
        >
          {(props) => (
            <Tooltip label={field.value ? field.value?.name : "faylni yuklang"}>
              <UploadButton type="button" {...props}>
                <FileUploadIcon />
                Fayl Yuklash
              </UploadButton>
            </Tooltip>
          )}
        </FileButton>
      )}
    />
  );
}
