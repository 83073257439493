import React from "react";
import { NotFoundContainer, Button, Wrapper, H3 } from "./style";
import NotFoundIcon from "../../assets/images/no-data.png";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <NotFoundContainer>
        <img src={NotFoundIcon} alt="not-found-icon" />
        <H3 className="mt-3">Sahifa ishlab chiqilmoqda yoki topilmadi!!!</H3>
        <Button onClick={() => navigate(-1)}>Ortga qaytish</Button>
      </NotFoundContainer>
    </Wrapper>
  );
}

export default NotFound;
