import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    width: 36px;
    height: 36px;
    border-radius: 1000px;
    background: #c3c3c3;
  }
  h1 {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
  }
`;
