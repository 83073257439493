// /* eslint-disable @typescript-eslint/no-explicit-any */
import { TextArea } from "./style";
import { Controller } from "react-hook-form";

export const TextareaComponent = ({ control, ...props }) => {
  return (
    <>
      <Controller
        control={control}
        name={props?.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          formState: { errors },
        }) => (
          <TextArea
            {...props}
            id={name}
            value={value || ""}
            onBlur={onBlur}
            onChange={onChange}
            onWheel={(e) => e.target.blur()}
            minLength={8}
            ref={ref}
            error={errors[props.name] ? errors[props.name]?.message : ""}
            styles={{
              rightSection: errors[props.name] && {
                border: "0.795144px solid #fa5252 !important",
                borderLeftColor: "transparent !important",
              },
            }}
          />
        )}
      />
    </>
  );
};
