import { clearUser, getUser } from "../../utils/user";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_ROOT;

const request = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
request.interceptors.request.use(
  async (config) => {
    const user = getUser();
    if (user) {
      if (!user?.isExpiredAccess) {
        clearUser();
        window.location.href = "/";
        return config;
      }
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user?.access}`,
      };
      return config;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export { request };
