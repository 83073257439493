import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Auth from "../pages/auth";
import Main from "../pages/main";
import NotFound from "../Components/not-found/not-found";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Auth />,
  },
  {
    path: "/main",
    element: <Main />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
