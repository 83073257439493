import { Controller } from "react-hook-form";
import { Container } from "./style";
import { Select } from "@mantine/core";
// import { useEffect } from "react";

export const SelectContainer = ({
  control,
  data = [],
  name,
  label,
  placeholder,
  defaultValue,
  disabled = false,
  searchable = true,
  onChange,
  clearable,
  ...props
}) => {

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? null}
        render={({ field, formState: { errors } }) => (
          <Container>
            <Select
              {...field}
              {...props}
              data={data || []}
              label={label || ""}
              searchable={searchable}
              clearable={clearable || false}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange();
              }}
              placeholder={placeholder || "Танланг"}
              disabled={disabled}
              error={errors[name] ? errors[name]?.message : ""}
            />
          </Container>
        )}
      />
    </>
  );
};
