import { Form, LoginLeft, Title, Wrapper } from "./style";
import { Input } from "../../Components/input";
import { InputPassword } from "../../Components/input-password";
import { useLogin } from "./useLogin";
import { Button, LoadingOverlay } from "@mantine/core";

export default function Auth() {
  const {
    form: { handleSubmit, control },
    onSubmit,
    isLoading,
  } = useLogin();

  return (
    <Wrapper>
      <LoginLeft className="sa">
        <div className="wrap-card">
        <Title>Tizimga kirish</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              control={control}
              name="username"
              placeholder="Login"
              label="Login"
              className="input-login"
            />
            <InputPassword
              control={control}
              name="password"
              placeholder="Parol"
              label="Parol"
              className="input-password"
            />
            <Button
              variant="filled"
              className="login-btn"
              loading={isLoading}
              type="submit"
            >
              Kirish
            </Button>
          </Form>
        </div>
      </LoginLeft>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        loaderProps={{
          color: "var(--background-surface-success-accent)",
        }}
        overlayProps={{
          radius: "sm",
          blur: 2,
        }}
      />
    </Wrapper>
  );
}
