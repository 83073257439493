import { Button, Grid } from "@mantine/core";
import { Container, ContainerButtons, DownloadButton, Line } from "./style.js";
import { TextareaComponent } from "../../../../Components/text-area/index.js";
import { FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import FileUpload from "../../../../Components/fileUpload/index.js";

export default function RightSide({
  rightOptions = null,
  onSubmit,
  form,
  handleRejection,
  isLoading,
}) {
  const baseURL = process.env.REACT_APP_API_ROOT;

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#1976D2";
      case 1:
        return "#F48E2F";
      case 2:
        return "#26BD6C";
      default:
        return "#E6483D";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Yangi";
      case 1:
        return "Ko‘rib chiqilmoqda";
      case 2:
        return "Ko’rib chiqildi";
      default:
        return "Rad etildi";
    }
  };

  const handleError = (error) => {
    if (error) {
      alert("Error: " + error.message);
    }
  };

  return (
    <FormProvider {...form} onError={handleError}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Container>
          <Grid gutter={24}>
            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
              <h1>Umumiy ma'lumot</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>ID:</p>
              <h1 className="id">{rightOptions?.id}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
              <p>Telefon raqam:</p>
              <h1 className="id">{rightOptions?.phone_number}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>Telegram:</p>
              <h1>@murojaat_agro_uz_test_bot</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>Status:</p>
              <h1
                className="status"
                style={{ color: getStatusColor(rightOptions.status) }}
              >
                {getStatusText(rightOptions.status)}
              </h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>Yosh:</p>
              <h1>{rightOptions.age ? `${rightOptions.age} yosh` : ""}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>Sana:</p>
              <h1>{dayjs(rightOptions?.created_at).format("DD.MM.YYYY")}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>FISH:</p>
              <h1>{rightOptions?.full_name}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <p>Manzil:</p>
              <h1>{rightOptions?.address}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
              <p>Taklif:</p>
              <h1>{rightOptions?.text}</h1>
            </Grid.Col>

            <Grid.Col span={12}>
              <Line />
            </Grid.Col>

            {rightOptions?.answer_file && (
              <Grid.Col span={12}>
                <h1>Xodim tomonidan berilgan javob:</h1>
              </Grid.Col>
            )}

            <Grid.Col span={12}>
              {!rightOptions?.answer_file ? (
                <FileUpload
                  name="answer_file"
                  disabled={
                    rightOptions?.status === 2 || rightOptions?.status === 3
                  }
                  {...rightOptions.answer_file}
                />
              ) : (
                <DownloadButton>
                  <h1>Biriktirilgan fayl:</h1>
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={baseURL + rightOptions?.answer_file?.path}
                    >
                      {rightOptions?.answer_file?.name}(
                      {rightOptions?.answer_file?.size})
                    </a>
                  </div>
                </DownloadButton>
              )}
            </Grid.Col>
            <Grid.Col span={12}>
              {rightOptions?.status === 2 || rightOptions?.status === 3 ? (
                <>
                  <p>Berilgan javob:</p>
                  <h1>{rightOptions?.answer}</h1>
                </>
              ) : (
                <div className="text-area-label">
                  Javob yozing:
                  <TextareaComponent
                    control={form.control}
                    name="answer"
                    placeholder="Yozing"
                    autosize
                    minRows={4}
                    required
                    disabled={
                      rightOptions?.status === 2 || rightOptions?.status === 3
                    }
                  />
                </div>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              {!(rightOptions?.status === 2 || rightOptions?.status === 3) && (
                <ContainerButtons>
                  <Button
                    color="red"
                    onClick={(e) => handleRejection(form.getValues(), e)}
                    loading={isLoading}
                    disabled={
                      rightOptions?.status === 2 || rightOptions?.status === 3
                    }
                  >
                    Rad etish
                  </Button>
                  <Button
                    color="green"
                    type="submit"
                    loading={isLoading}
                    disabled={
                      rightOptions?.status === 2 || rightOptions?.status === 3
                    }
                  >
                    Tasdiqlash
                  </Button>
                </ContainerButtons>
              )}
            </Grid.Col>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
}
