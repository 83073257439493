import { Controller } from "react-hook-form";
import { Container } from "./style";
import Calendar from "../../assets/icons/Calendar";
import { DatePickerInput } from "@mantine/dates";

export const DatePicker = ({
  control,
  name,
  label,
  placeholder,
  disabled = false,
  ...props
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, formState: { errors } }) => (
          <Container>
            <DatePickerInput
              {...field}
              {...props}
              rightSection={<Calendar />}
              valueFormat="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              label={label || ""}
              placeholder={placeholder || "YYYY.MM.DD"}
              disabled={disabled}
              error={errors[name] ? errors[name]?.message : ""}
              locale="uz"
            />
          </Container>
        )}
      />
    </>
  );
};
