import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Button, MantineProvider, Text, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/react-query.js";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "./index.css";
import { ModalsProvider } from "@mantine/modals";
const theme = createTheme({
  /** Your theme override here */
});
// const modals = {
//   demonstration: TestModal,
//   /* ...other modals */
// };
// declare module '@mantine/modals' {
//   export interface MantineModalsOverride {
//     modals: typeof modals;
//   }
// }
const TestModal = ({ context, id, innerProps }) => (
  <>
    <Text size="sm">{innerProps.modalBody}</Text>
    <Button fullWidth mt="md" onClick={() => context.closeModal(id)}>
      Close modal
    </Button>
  </>
);
const modals = {
  demonstration: TestModal,
  /* ...other modals */
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        null
        // <WrapLoader>
        //   <Loader variant="bars" />
        // </WrapLoader>
      }
    >
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme}>
          <ModalsProvider
            modals={modals}
            labels={{ confirm: "Submit", cancel: "Cancel" }}
          >
            <Notifications position="top-right" />
            <App />
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
