import styled from "@emotion/styled";

export const UploadButton = styled.button`
  width: 160px;
  height: 48px;
  display: flex;
  border: 1px solid #1976d2;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  background: #1976d2;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  line-height: 20px;
  letter-spacing: -0.07px;
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 100%;
  }
`;
