import styled from "@emotion/styled";
import { allColors } from "../../styles/colors.js";

export const Wrapper = styled.div`
  width: 100%;
`;

export const H3 = styled.h3`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: ${allColors.shriftColor};
`;

export const NotFoundContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 14px;
  img {
    width: 200px;
    color: ${allColors.mainGreen};
  }
`;
export const Button = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px;
  color: ${allColors.mainGreen};
  background-color: red;
  cursor: pointer;
`;
