import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { getAllRequest } from "../../../shared/modules/getAllRequest";
import { useState } from "react";
import { modals } from "@mantine/modals";
import { request } from "../../../shared/api/requests";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./form.schema";

export function FeedbackAnswerHook({ refetch }) {
  const location = useLocation();
  let object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const [itemId, setItemId] = useState(null);
  const rightForm = useForm({
    resolver: yupResolver(formSchema),
  });
  const { data: rightOptions, refetch: refetchRight } = useQuery(
    ["get-right-data", params?.id],
    () => {
      setItemId(params?.id);
      return getAllRequest(`/api/feedback/${params?.id}`);
    },
    {
      onSuccess: (res) => {
        rightForm.reset(res.data);
      },
      enabled: !!params?.id,
    }
  );
  const { mutate: postMutate, isLoading } = useMutation(
    (data) =>
      request.put(`/api/feedback/review/${itemId}/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        refetchRight();
        refetch();
      },
      onError: () => {},
    }
  );
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("status", 2);
    formData.append("answer", data?.answer);
    if (data?.answer_file !== null) {
      formData.append("answer_file", data?.answer_file);
    }
    modals.openConfirmModal({
      centered: true,
      title: "Tasdiqlash",
      children: <p>Haqiqatdan ham tasdiqlamoqchimisiz?</p>,
      labels: { confirm: "Ha", cancel: "Yo'q" },
      confirmProps: { color: "#26BD6C" },

      onConfirm: () => postMutate(formData),
    });
  };
  const handleRejection = (data) => {
    const formData = new FormData();
    formData.append("status", 3);
    formData.append("answer", data?.answer);
    if (data?.answer_file !== null) {
      formData.append("answer_file", data?.answer_file);
    }
    modals.openConfirmModal({
      centered: true,
      title: "Rad etish",
      children: <p>Haqiqatdan ham rad etmoqchimisiz?</p>,
      labels: { confirm: "Ha", cancel: "Yo'q" },
      confirmProps: { color: "#26BD6C" },

      onConfirm: () => postMutate(formData),
    });
  };
  return {
    rightForm,
    onSubmit,
    rightOptions,
    handleRejection,
    isLoading,
  };
}
