import { Grid } from "@mantine/core";
import { Container, GridContainer, NavLinkStyle } from "./style";
import { useLocation } from "react-router-dom";

export default function Request({ leftOptions = [] }) {
  const location = useLocation();
  let object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#1976D2";
      case 1:
        return "#F48E2F";
      case 2:
        return "#26BD6C";
      default:
        return "#E6483D";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Yangi";
      case 1:
        return "Ko‘rib chiqilmoqda";
      case 2:
        return "Ko’rib chiqildi";
      default:
        return "Rad etildi";
    }
  };

  const sortedLeftOptions = [...leftOptions].sort((a, b) => b.id - a.id);

  return (
    <Container>
      {sortedLeftOptions.map((item) => (
        <NavLinkStyle
          to={`?${new URLSearchParams({
            ...params,
            id: item?.id,
          })}`}
          className={item.id === Number(params?.id) ? "active-link" : ""}
          key={item.id}
        >
          <GridContainer>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <p>ID:</p>
              <h1 className="id">{item.id}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <p>Sana:</p>
              <h1>{item.created_at}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 10, md: 8, lg: 4 }}>
              <h1
                className="status"
                style={{ backgroundColor: getStatusColor(item.status) }}
              >
                {getStatusText(item.status)}
              </h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <p>FISH:</p>
              <h1>{item.full_name}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <p>Yosh:</p>
              <h1>{item.age ? `${item.age} yosh` : ""}</h1>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
              <p>Manzil:</p>
              <h1>{item.address}</h1>
            </Grid.Col>
          </GridContainer>
        </NavLinkStyle>
      ))}
    </Container>
  );
}
