export const allColors = {
  mainColor: " #264DB1;",
  shriftColor: "#313131",
  shriftColorOpasity3: "#60676D",
  whiteColor: "#ffffff",
  selectBgColor: "#F5F5F5",
  shriftRed: "#EB5757",
  seriyColor: "#E7E7E7",
  colorBlue: "#2E7DF6",
  blue: {
    300: "#E9F5FE",
  },
};
