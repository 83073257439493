import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 91.96vh;
  padding: 24px;
  overflow-y: auto;
  p {
    color: #303030;
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
  }

  h1 {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .id {
    color: #26bd6c;
  }

  .status {
    display: flex;
    padding: 2px 3px;
    height: 25px;
    align-items: center;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
  }

  .text-area-label {
    color: #303030;
    font-family: "Inter";
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Line = styled.div`
  height: 1px;
  background-color: #c8cad0;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  
  @media (max-width: 600px) {
    flex-wrap: wrap;
    button{
      width: 100%;
    }
  }
`;

export const DownloadButton = styled.div`
  h1 {
    color: #303030;
    font-family: "Inter";
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }
  div {
    display: flex;
    align-items: center;
    gap: 6px;
    a {
      text-decoration: none;
      color: blue;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;
