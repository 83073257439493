import { Input } from "@mantine/core";
import { Container } from "./style";
import Search from "../../assets/icons/Search";
import { Controller } from "react-hook-form";

export default function SearchFilter({ control, ...props }) {
  return (
    <Controller
      name={props.name}
      control={control}
      {...props}
      render={({ field, formState: { errors } }) => (
        <Container>
          <Input
            {...field}
            {...props}
            leftSection={<Search />}
            control={control}
            placeholder="Qidirish"
            error={errors[props.name] ? errors[props.name]?.message : ""}
          />
        </Container>
      )}
    />
  );
}
