import Request from "../../../../Components/Request";
import { Container, ContainerFilter } from "./style";
import { DatePicker } from "../../../../Components/DateFilter";
import SearchFilter from "../../../../Components/SearchFilter";
import { SelectContainer } from "../../../../Components/SelectFilter";

export default function LeftSide({ form, leftOptions, mutate }) {
  const statuses = [
    { value: "all", label: "Barchasi" },
    { value: "0", label: "Yangi" },
    { value: "1", label: "Ko‘rib chiqilmoqda" },
    { value: "2", label: "Ko‘rib chiqildi" },
    { value: "3", label: "Rad etildi" },
  ];

  return (
    <Container>
      <ContainerFilter>
        <SelectContainer control={form.control} data={statuses} name="status" />
        <DatePicker control={form.control} name="from_date" />
        <SearchFilter control={form.control} name="search" />
      </ContainerFilter>
      <Request leftOptions={leftOptions} mutate={mutate} />
    </Container>
  );
}
